import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
    const location = useLocation();

    return (
        <div className="header">
            <Link to="/" className={location.pathname === "/" ? "active" : ""}><img src="/image/FortWorthInnovatorsForumLogo.png" alt="Fort Worth Innovators Forum" /></Link>
            <nav>
                <Link to="/" className={location.pathname === "/" ? "active" : ""}>Home</Link>
                <Link to="/about" className={location.pathname === "/about" ? "active" : ""}>About</Link>
                <Link to="/event" className={location.pathname === "/event" ? "active" : ""}>Events</Link>
                <Link to="/workshop" className={location.pathname === "/workshop" ? "active" : ""}>Workshops</Link>
                <Link to="/competition" className={location.pathname === "/competition" ? "active" : ""}>Competition</Link>
            </nav>
        </div>
    );
};

export default Header;
