import React from "react";

const rawHTML = `
    <div style="width: 100%; height: 100%; background: white; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: inline-flex">
        <div style="align-self: stretch; flex: 1 1 0; padding: 80px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 120px; display: flex">
            <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 40px; display: flex">
                <div style="width: 1225px; color: black; font-size: 60px; font-family: Times; font-weight: 400; word-wrap: break-word">Innovation Competition 2024</div>
                <div style="align-self: stretch; justify-content: space-between; align-items: flex-start; display: inline-flex">
                    <div style="width: 537px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">The Fort Worth Innovators Forum hosts the Annual Innovation Competition. Our aim is to foster inventions with potential commercial applications by throwing a spotlight on innovative engineers, inventors, builders, and scientists laboring in obscurity in Fort Worth and Tarrant County.</div>
                    <div style="width: 537px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">The competition spans six months from announcement of challenges to the awards ceremony and includes mentoring, events and other activities that aim to derisk the invention and prepare it for commercial adoption.</div>
                </div>
            </div>
            <div style="width: 853px"><span style="color: black; font-size: 24px; font-family: Times; font-weight: 700; word-wrap: break-word">We invite you to participate:<br/></span>
                <ul>
                    <li><a href='#innovator'>Innovator</a></li>
                    <li><a href='#sponsor'>Sponsor</a></li>
                    <li><a href='#mentor'>Mentor</a></li>
                    <li><a href='#volunteer'>Volunteer</a></li>
                </ul>
            </div>

            <div style="width: 853px"><span style="color: black; font-size: 40px; font-family: Times; font-weight: 400; word-wrap: break-word"><h3>Process</h3></span>
                <ol>
                    <li>Challenges are announced</li>
                    <li>Sponsors present reverse pitches by Zoom or in person</li>
                    <li>Idea submissions closed</li>
                    <li>Stipends are awarded, 5-10 per challenge</li>
                    <li>Workshops, hackathons, mentoring, access to facilities</li>
                    <li>Presentations are made to sponsors and judges</li>
                    <li>Awards ceremony</li>
                </ol>
            </div>

            <div style="align-self: stretch; justify-content: flex-start; align-items: center; gap: 40px; display: inline-flex">
                <div style="width: 531px"><span style="color: black; font-size: 40px; font-family: Times; font-weight: 400; word-wrap: break-word"><a name='innovator'><h3>Innovators</h3></a></span><span style="color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">If you are the kind of person who cannot resist tackling interesting and challenging problems, then this is your opportunity to shine. You will be presented with a variety of challenges among which to choose where to focus your spare time over the next six months.<br/><br/>But you won’t be on your own. You will have access to a network of mentors, facilities, and resources you can draw on to develop your idea into a valuable invention.</span></div>
                <div style="width: 448px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">There will be prizes for the best solutions. But, more importantly, you will retain all intellectual property rights to your invention. <br/><br/>Sponsoring companies may offer to license your invention or you may meet an entrepreneur who wants to form a new startup based on your invention. Or you may decide to form your own startup.</div>
            </div>
            <div style="width: 448px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">Who Should Apply
                <ul>
                    <li>Engineer</li>
                    <li>Problem solver</li>
                    <li>Innovator</li>
                    <li>Inventor</li>
                </ul>
                <p>To learn more, email your contact information to: <a href='mailto:ipser@ipser.com'>ipser@ipser.com</a>.</p>
            </div>
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 158px; display: inline-flex">
                <div style="width: 534px"><span style="color: black; font-size: 40px; font-family: Times; font-weight: 400; word-wrap: break-word"><a name='sponsor'><h3>Sponsors</h3></a><br/></span><span style="color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">If you have more problems than you can solve, why not enlist the best and brightest minds to tackle them for you? As a sponsor of the Innovation Competition you will offer a challenge to the innovator community in reverse pitch, describing what you need and what a potential solution should look like.<br/><br/>By sponsoring a challenge, you will tap into a wider community of innovators who are competing to solve your toughest problems.<br/><br/>You set your own criteria and establish the cash stipends and prizes. You judge the initial ideas and final solutions to your own challenges and award the prizes.<br/>We are also seeking cash and in-kind sponsors who can provide resources and open their facilities to innovators.<br/><br/><p>To learn more, email your contact information to: <a href='mailto:ipser@ipser.com'>ipser@ipser.com</a>.</p></div>
                <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 120px; display: inline-flex">
                    <div style="width: 638px"><span style="color: black; font-size: 40px; font-family: Times; font-weight: 400; word-wrap: break-word"><a name='mentor'><h3>Mentors</h3></a></span><span style="color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">If you share our goals of fostering innovation and you have valuable knowledge and skills to share, become a mentor to innovators. As a mentor you will work with innovators to help them solve the challenges.<br/><br/></span><span style="color: black; font-size: 24px; font-family: Times; font-weight: 700; word-wrap: break-word">We are seeking mentors with expertise in:<br/></span><span style="color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">Electrical engineering<br/>Mechanical engineering<br/>Chemical engineering<br/>Aerospace engineering<br/>Manufacturing<br/>Software engineering<br/>Physics, chemistry, and biology<br/>Intellectual property law<br/>Public speaking<br/></span><span style="color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word"><br/>                    <p>To learn more, email your contact information to: <a href='mailto:ipser@ipser.com'>ipser@ipser.com</a>.</p></div>
                </div>
            </div>
            <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 158px; display: inline-flex">
                <div style="width: 534px"><span style="color: black; font-size: 40px; font-family: Times; font-weight: 400; word-wrap: break-word"><a name='volunteer'><h3>Volunteers</h3></a></span><span style="color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word"><p>We also need volunteers to help organize and run the Innovation Competition.</p>
                    <p>We are seeing organizers with experience in:</p>
                    <ul>
                    <li>Event management</li>
                    <li>Marketing</li>
                    <li>Graphic design</li>
                    </ul>
                    <p>To learn more, email your contact information to: <a href='mailto:ipser@ipser.com'>ipser@ipser.com</a>.</p>
                </div>
            </div>
        </div>
    </div>
`;

const Competition = () => {
    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
        </>
    );
};

export default Competition;
