import React from "react";
import { Link } from "react-router-dom";

const WorkShop = () => {
  return (
    <>
      <main class="workshop--body">
        <div class="workshop-top-headings">
          <h2 class="top-heading">Creativity Workshop</h2>
          <h2>Workshops will resume January 2025</h2>
          <h3><Link to="https://www.createfw.com">CreateFW: 400 Bryan Avenue, Ft. Worth 76104</Link></h3>
          <h3 class="free">Fee: Free</h3>
        </div>
        <div class="workshop-para">
          <p>
            Do you face challenges that don't lend themselves to obvious or
            straightforward solutions? Is hard work not paying off for you
            anymore? Do you need new insights to move your business forward? Do
            you worry that your ideas are not worth the investment of time and
            energy required to bring them to fruition? You may need to become
            more creative in your business problem solving.
          </p>
          <p>
            Creativity is the formation of something original, either tangible,
            such as work of art, or intangible, such as an idea. Creativity can
            be applied to descriptions, to how we see the world. It can be
            applied to problem solving and it can be used to design new things.
            Mastering creativity opens up a whole new world of possibilities. It
            enlists your imagination as an effective problem solving tool.
          </p>
          <p>
            While this workshop series is business-focused, it adds another
            dimension of thought often missing from practical disciplines.
            Anyone who wants to find and develop a new business idea will
            benefit from it. In this first creativity workshop you will learn:
          </p>
          <div>
            <ol>
              <li>What is creativity</li>
              <li>Why it literally pays to be more creative</li>
              <li>What is the role of play in creativity</li>
              <li>What is an epiphany</li>
              <li>
                How to apply creativity in your business and personal life
              </li>
            </ol>
          </div>
          <p>
            Participants can expect to come away from this workshop series with
            a greater creativity in every task they approach both individually
            and in collaboration with others. This includes the ability to
            invent new possibilities and to solve problems more effectively.
            Creativity is the foundation to creating new products, services,
            processes, business models, and startups. Additionally, creative
            individuals are more engaged and productive even in tasks that don’t
            require creativity.
          </p>
          <p>
            This workshop is a series of one hour planned, instructor-led
            presentations in which concepts are taught and reinforced through
            discussions, demonstrations, and exercises, using real situations
            and problems that the participants face.
          </p>
          <p>The overall series will include:</p>
        </div>
        <div class="workshop-list">
          <div>
            <h3>Creativity</h3>
            <ol>
              <li>Introduction</li>
              <li>Insight</li>
              <li>Curation</li>
              <li>Development</li>
              <li>Lateral Thinking</li>
              <li>Collaboration</li>
              <li>Management</li>
            </ol>
          </div>
          <div>
            <h3>Innovation</h3>
            <ol>
              <li>Introduction</li>
              <li>Insight</li>
              <li>Curation</li>
              <li>Development</li>
              <li>Lateral Thinking</li>
              <li>Collaboration</li>
              <li>Management</li>
            </ol>
          </div>
          <div>
            <h3>Startups</h3>
            <ol>
              <li>Introduction</li>
              <li>Focus</li>
              <li>Marketing and Sales</li>
              <li>Operations</li>
              <li>Finances</li>
              <li>Experimentation</li>
              <li>Cofounders</li>
              <li>Incorporation</li>
              <li>Funding</li>
            </ol>
          </div>
        </div>
      </main>
      <main class="ED">
        <div class="ED-section">
          <div class="ED-img-container">
            <img src="./image/EdIpser.jpg" alt="Ed Ipser" />
          </div>
          <div class="Ed-detal">
            <h2>Ed Ipser Jr.</h2>
            <p>
              Edward A. Ipser, Jr. PhD is President of IpserLab LLC, a startup
              foundry and Managing Partner at Coactify Inc., a creative pivot
              and turnaround consultancy. He has founded, cofounded, joined, and
              advised many startups. As founder of MarketFusion he raised $23M
              in venture capital. He also founded SVASE (Silicon Valley
              Association of Software Entrepreneurs) in 1996 which became the
              premier Silicon Valley entrepreneurial organization before merging
              into SVForum. He graduated from TCU with majors in Math,
              Psychology, and Economics and received a PhD in computer science
              from USC
            </p>
          </div>
        </div>
      </main>
    </>
  );
};

export default WorkShop;
