import React from 'react'
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from './component/Header.jsx';
import Footer from './component/Footer.jsx';
import Home from './component/Home.jsx';
import About from './component/About.jsx';
import Event from './component/Event.jsx';
import WorkShop from './component/WorkShop.jsx';
import Competition from './component/Competition.jsx';
import BusinessPioneersClub from './component/BusinessPioneersClub.jsx';
const App = () => {
  return (
    <BrowserRouter>
      <div className='container'>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/event" element={<Event />} />
          <Route path="/workshop" element={<WorkShop />} />
          <Route path="/competition" element={<Competition />} />
          <Route path="/businesspioneersclub" element={<BusinessPioneersClub />} />
          <Route path="/bpc" element={<BusinessPioneersClub />} />
          <Route path="/thinktank" element={<BusinessPioneersClub />} />
        </Routes>
       <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App
