import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {

  return (
    <div className="footer">
        <center>
            <Link to="https://www.hscnext.com"><img src="/image/HscNextLogo.png" alt="HSC/Next"/></Link>
            <Link to="https://www.techfortworth.org"><img src="/image/TechFwLogo.png" alt="Tech Fort Worth"/></Link>
            <Link to="https://www.createfw.com"><img src="/image/CreateFwLogo.png" alt="Create Fort Worth"/></Link>
            <Link to="https://www.ipserlab.com"><img src="https://www.ipserlab.com/images/image/IpserLabLogo.jpg" alt="IpserLab"/></Link>
            <Link to="https://www.themarketingblender.com"><img src="/image/MarketingBlenderLogo.png" alt="The Marketing Blender"/></Link>
            <Link to="https://www.coactify.com"><img src="https://www.coactify.com/images/image/CoactifyLogo.png" alt="Coactify"/></Link>
            <Link to="https://www.losaltoslaw.com"><img src="/image/LosAltosLawGroupLogo.png" alt="Los Altos Law Group"/></Link>
            <Link to="https://www.barrettdesigns.io"><img src="/image/BarrettDesignsLogo.jpg" alt="Barrett Designs"/></Link>
            <p>For more information, contact <Link to="https://www.linkedin.com/in/ipser/" target='new'>Edward A. Ipser, Jr.</Link> (<Link to="mailto:ipser@ipser.com">ipser@ipser.com</Link>). Copyright &copy; Edward A. Ipser, Jr. All rights reserved.</p>
        </center>
    </div>
  );
};

export default Footer;
