import React from "react";
import { Link } from "react-router-dom";

const Event = () => {
    return (
        <>

            <div class="event">
                <div class="event-left">
                    <div style={{ backgroundColor: "#05B3F3" }}>
                        <div>
                            <img src="./image/TechFwLogo.png" alt="TechFW"/>
                        </div>
                        <div>
                            <h3>Friday, October 11, 2024 Noon-1PM</h3>
                            <p><Link to="https://forms.office.com/pages/responsepage.aspx?id=f4_QI55t1UyXzyBGfx7cXwsdQfSSVedImw4PlZO-HflUNTgyTVNJRU5PQ0NVVlBJNEtHMFRLMUpaTS4u"><img src="./image/RsvpButton.jpg" alt="RSVP"/></Link></p>
                            <p><Link to="https://www.hscnext.com">HSC Next</Link></p>
                            <p>550 Bailey Ave #510</p>
                            <p>Fort Worth, TX 76107</p>
                            <p><Link to="https://www.google.com/maps/place/550+Bailey+Ave+%23510,+Fort+Worth,+TX+76107">Directions</Link></p>
                        </div>
                    </div>
                </div>
                <div class="event-right">
                    <h2>Founder Spotlight and Seed Fund Announcement</h2>
                    <p>TechFW and Fort Worth Innovators Forum invite you to our inaugural collaborative event following our newly established partnership to amplify innovation and entrepreneurship within Fort Worth's growing startup ecosystem.</p>
                    <p>Meet 5 visionary TechFW founders, hear their groundbreaking solutions, and enjoy Q&A and networking opportunities. And we’ll share an exclusive seed fund announcement for early-stage founders.</p>
                </div>
            </div>

            <div class="event">
                <p>Parking is free. Park on the 4th floor of the parking structure. Please do not park in the visitor spots to the north and east of 550 Bailey.</p>
                <ol>
                    <li>Turn into the lot for 550 Bailey and immediately turn right (north) to access the gate that leads to the parking garage.</li>
                    <li>If the gate arm is down, press the silver button for the intercom and tell the guard you are here for the event at HSC.</li>
                    <li>Drive to the 4th level and park close to the elevator bank</li>
                    <li>Take the elevator to the ground floor and enter the building from the west side</li>
                    <li>Walk to the lobby and take the elevators up to the 5th floor. Ask the security guard to direct you if needed.</li>
                    <li>Exit the elevator and enter the double glass doors. Tell the receptionist at the front desk that you are here for the FWIC event and she will direct you.</li>
                </ol>
            </div>

        </>
    );
};

export default Event;
