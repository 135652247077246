import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div class="home">
        <div class="home-left">
          <div>
            <h3><Link to="/about">Where Fort Worth Learn to Innovates</Link></h3>
            <p>Each month the Innovators Forum welcomes local innovators and experts to explore the many aspects of starting, scaling, and maintaining new, innovative businesses.</p>
          </div>
        </div>
        <div class="home-right">
          <div>
            <h2>2024</h2>
            <h3><Link to="/competition">Innovation Competition</Link></h3>
            <p>The Innovators Forum is proud to announce the first annual Innovation Competition in collaboration with TechFW and the City of Fort Worth Economic Development Department.</p>
          </div>
        </div>
      </div>
      <div class="home-event">
        <div class="home-event-left">
        </div>
        <div class="home-event-middle">
          <h3><Link to="/event">Founder Spotlight and Seed Fund Announcement</Link></h3>
			<img src="./image/TechFwLogo.png" alt="TechFW"/>
          <div>
			<p>TechFW and Fort Worth Innovators Forum invite you to our inaugural collaborative event following our newly established partnership to amplify innovation and entrepreneurship within Fort Worth's growing startup ecosystem.</p>
            <p><Link to="/event">Details</Link></p>
          </div>
          <div>
            <h3><Link to="/event">Friday, October 11, 2024 Noon-1PM</Link></h3>
          </div>
        </div>
        <div class="home-event-right">
        </div>
      </div>
    </>
  );
};

export default Home;
