import React from "react";

const rawHTML = `
    <div style="width: 100%; height: 100%; background: white; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: inline-flex">
        <div style="align-self: stretch; flex: 1 1 0; padding: 80px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 120px; display: flex">
            <div style="align-self: stretch; height: 312px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 40px; display: flex">
                <div style="align-self: stretch; height: 160px; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: flex">
                    <div style="align-self: stretch; color: black; font-size: 60px; font-family: Times; font-weight: 400; line-height: 80px; word-wrap: break-word">The Fort Worth Innovators Forum is where entrepreneurs and innovators gather to envision and create the future. </div>
                </div>
                <div style="align-self: stretch; justify-content: space-between; align-items: flex-start; display: inline-flex">
                    <div style="width: 685px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">Aspiring entrepreneurs and innovators can discover their own unique path to success because we bring the best of Fort Worth together every month with noted speakers and panel discussions as well as workshops, hackathons, and job fairs. </div>
                    <div style="width: 798px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">Our goal is to be the wide end of the startup funnel by providing an entryway to the entrepreneurially curious. Our mission is to make Fort Worth the innovation capital of Texas. The Fort Worth Innovators Forum empowers members to innovate in startups, corporations, and other situations.</div>
                </div>
            </div>
            <div style="align-self: stretch; height: 504px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 40px; display: flex">
                <div style="height: 260px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 24px; display: flex">
                    <div style="align-self: stretch; height: 28px; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: flex">
                        <div style="color: black; font-size: 24px; font-family: Times; font-weight: 700; word-wrap: break-word">In order to accomplish this we are:</div>
                    </div>
                    <div style="align-self: stretch; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
                        <div style="align-self: stretch; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
                            <div style="height: 56px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 4px; display: flex">
                                <div style="width: 390px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">Building a community of peer entrepreneurs and innovators.</div>
                            </div>
                            <div style="height: 84px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 4px; display: flex">
                                <div style="width: 389px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">Providing technology and entrepreneurial curriculum and mentoring</div>
                            </div>
                        </div>
                        <div style="width: 390px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                            <div style="padding-right: 282px; flex-direction: column; justify-content: center; align-items: flex-start; gap: 12px; display: flex">
                                <div style="align-self: stretch; height: 84px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                                    <div style="width: 396px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">Fostering a network of angel investors for the first investments in startups.</div>
                                </div>
                                <div style="width: 390px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">Developing partnerships between universities and industry for innovation, technology transfer, and education.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height: 204px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 24px; display: flex">
                    <div style="align-self: stretch; height: 28px; padding-right: 1578.82px; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: flex">
                        <div style="color: black; font-size: 24px; font-family: Times; font-weight: 700; word-wrap: break-word">We are reaching out to engage:</div>
                    </div>
                    <div style="width: 798px; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
                        <div style="flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
                            <div style="width: 384px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">The entrepreneurially curious, especially those with technical skills or domain expertise.</div>
                            <div style="height: 56px; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: flex">
                                <div style="width: 389px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">Those already running a business seeking to grow through innovation.</div>
                            </div>
                        </div>
                        <div style="flex: 1 1 0; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 12px; display: inline-flex">
                            <div style="align-self: stretch; height: 84px; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: flex">
                                <div style="width: 387px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">Corporate innovators including CTOs, product managers, and engineers.</div>
                            </div>
                            <div style="align-self: stretch; height: 56px; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: flex">
                                <div style="width: 387px; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">Technical and entrepreneurial students and educators.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="align-self: stretch; justify-content: space-between; align-items: flex-start; display: inline-flex">
                <div style="width: 500px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 24px; display: inline-flex">
                    <div style="align-self: stretch; height: 28px; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: flex">
                        <div style="color: black; font-size: 24px; font-family: Times; font-weight: 700; word-wrap: break-word">Startup Culture</div>
                    </div>
                    <div style="align-self: stretch; height: 252px; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: flex">
                        <div style="align-self: stretch; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">We are building a startup culture in North Texas centered on Fort Worth focusing, first, on the earliest stage of startup formation. That means attracting the attention of the entrepreneurially curious who have the skills and aptitude to create a startup. The ideal startup founder is someone with deep industry experience and a unique startup idea from that industry around which a team can be formed and a startup can be created.</div>
                    </div>
                </div>
                <div style="width: 500px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 24px; display: inline-flex">
                    <div style="align-self: stretch; height: 28px; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: flex">
                        <div style="color: black; font-size: 24px; font-family: Times; font-weight: 700; word-wrap: break-word">Corporate Innovation</div>
                    </div>
                    <div style="align-self: stretch; height: 252px; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: flex">
                        <div style="align-self: stretch; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">We are helping local businesses become more innovative and competitive worldwide. That means promoting innovation to executives and managers and enabling product managers and employees to become more innovative. We are inspiring those who are not technical to create new scalable companies or to add scalability to existing companies through, for example, digital transformation.</div>
                    </div>
                </div>
                <div style="width: 388px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 24px; display: inline-flex">
                    <div style="align-self: stretch; height: 28px; padding-right: 1503.01px; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: flex">
                        <div style="color: black; font-size: 24px; font-family: Times; font-weight: 700; word-wrap: break-word">University/Industry Collaboration</div>
                    </div>
                    <div style="align-self: stretch; height: 112px; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: flex">
                        <div style="align-self: stretch; color: black; font-size: 24px; font-family: Times; font-weight: 400; word-wrap: break-word">We are facilitating industry and university collaboration in the development of technology and research. Including:</div>
                    </div>
                </div>
            </div>
            <div style="align-self: stretch; justify-content: center; align-items: flex-start; gap: 20px; display: inline-flex">
                <div style="flex: 1 1 0; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: inline-flex">
                    <div style="align-self: stretch; color: black; font-size: 26px; font-family: Times; font-weight: 400; word-wrap: break-word">Intellectual property commercialization</div>
                </div>
                <div style="flex: 1 1 0; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: inline-flex">
                    <div style="align-self: stretch; color: black; font-size: 26px; font-family: Times; font-weight: 400; word-wrap: break-word">Research sponsorship and collaboration</div>
                </div>
                <div style="flex: 1 1 0; flex-direction: column; justify-content: flex-start; align-items: flex-start; display: inline-flex">
                    <div style="align-self: stretch; color: black; font-size: 26px; font-family: Times; font-weight: 400; word-wrap: break-word">Senior design thesis</div>
                </div>
            </div>
        </div>
    </div>
`;


const About = () => {
    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
        </>
    );
};

export default About;
