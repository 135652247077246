import React from "react";
import { Link } from "react-router-dom";

const BusinessPioneersClub = () => {
    return (
        <>

            <div class="event">
                <div>
                    <div style={{ backgroundColor: "#05B3F3" }}>
                        <div>
                            <center>
                            <h1>Premier Event</h1>
                            <br/>
                            <h1>Wednesday</h1>
                            <h1>September 25</h1>
                            <h1>1-2 PM</h1>
                            <p><Link to="https://www.hscnext.com">HSC Next</Link></p>
                            <p>550 Bailey Ave #510</p>
                            <p>Fort Worth, TX 76107</p>
                            <p><Link to="https://www.google.com/maps/place/550+Bailey+Ave+%23510,+Fort+Worth,+TX+76107">Directions</Link></p>
                            <br/>
                            <p><i>By Invitation Only</i></p>
                            <p><Link to="mailto:ipser@ipser.com?subject=RSVP: Fort Worth Business Pioneers Club"><img src="./image/RsvpButton.jpg" alt="RSVP"/></Link></p>
                            </center>
                        </div>
                        <div>
                            <center>
                            <h2>Special Guests</h2>
                            </center>
                        </div>
                        <div>
                            <center>
                            <a href='https://www.linkedin.com/in/carlo-capua-6b644a15/'><img src="./image/CarloCapua.jpg" alt="Carlo Capua"  width='300'/></a>
                            <h2>Carlo Capua, Chief of Strategy and Innovation, City of Fort Worth</h2>
                            </center>Tim Riddle
                        </div>
                        <div>
                            <center>
                            <a href='https://www.linkedin.com/in/tomwavering/'><img src="./image/TomWavering.jpg" alt="Tom Wavering"  width='300'/></a>
                            <h2>Tom Wavering, Chief University Strategy and Innovation Officer, Texas Christian University</h2>
                            </center>
                        </div>
                        <div>
                            <center>
                            <a href='https://www.linkedin.com/in/tim-riddle-538a9744/'><img src="./image/TimRiddle.jpg" alt="Tim Riddle"  width='300'/></a>
                            <h2>Tim Riddle, President, Custom Information Services</h2>
                            </center>
                        </div>
                    </div>
                </div>
                <div class="event-right">
                    <h2>Business Pioneers Club</h2>
                    <p>The Fort Worth Business Pioneers Club is an association of senior executives from Fort Worth enterprises who recognize the challenges we face and seek to develop and maintain a competitive edge through collaboration with our peers.</p>
                    <p>&#9658; Learn about new technologies and trends and how you can utilize them.</p>
                    <p>&#9658; Build networks with local executives.</p>
                    <p>&#9658; Take a longer view to anticipate and prepare for the challenges ahead.</p>

                    <h2>Mastering the Unknown: Bold Moves to Future Proof Your Company</h2>
                    <p>Gain proven wisdom from an expert panel on bold risk-taking in tough times, and hear a visionary presentation on how future thinking is essential for your long-term success. You will learn practical ways to elevate your decision-making quality and become a more considerate, forward-thinking leader. Don't miss this transformative event!</p>

                    <h2>Schedule</h2>
                    <table>
                    <tr>
                    <td>Networking</td>
                    <td>25 minutes</td>
                    <td>12:45 PM</td>
                    </tr>
                    <tr>
                    <td>Welcome, background, purpose (Cameron Cushman)</td>
                    <td>5 minutes</td>
                    <td>1:10 PM</td>
                    </tr>
                    <tr>
                    <td>Panel: “Bold Risk Taking In A Harsh Environment…A How-To From 3 Serious Leaders” (Carlo Capua, Tom Wavering, Tim Riddle)</td>
                    <td>30 minutes</td>
                    <td>1:15 PM</td>
                    </tr>
                    <tr>
                    <td>Talk: “Future Thinking And Long-Term Thriving” (Joshua Brockway)</td>
                    <td>15 minutes</td>
                    <td>1:45 PM</td>
                    </tr>
                    <tr>
                    <td>Call to Action (Ryan Brown)</td>
                    <td>5 minutes</td>
                    <td>2:00 PM</td>
                    </tr>
                    <tr>
                    <td>Networking</td>
                    <td>30 minutes</td>
                    <td>2:00 PM</td>
                    </tr>
                    </table>

                    <h2>Membership</h2>
                    <p>Silver: $100/year (one person)</p>
                    <p>Gold: $1000/year (up to 3 senior executives)</p>
                    <p>Platinum: $5000/year (sponsorship and unlimited guests)</p>

                    <h2>Hosts</h2>
                    <p>The Fort Worth Business Pioneers Club hosts quarterly events for the benefit of its members. Ask how you can join.</p>
                    <p><Link to="https://www.ipser.com"><img src="/image/FortWorthInnovatorsForumLogo.png" alt="Fort Worth Innovators Forum"/></Link></p>
                    <p><Link to="https://www.hscnext.com"><img src="/image/HscNextLogo.png" alt="HSC/Next"/></Link></p>
                    <p><Link to="https://www.techfortworth.org"><img src="/image/TechFwLogo.png" alt="Tech Fort Worth"/></Link></p>
                </div>
            </div>

            <div class="event">
                <p>Parking is free. Park on the 4th floor of the parking structure. Please do not park in the visitor spots to the north and east of 550 Bailey.</p>
                <ol>
                    <li>Turn into the lot for 550 Bailey and immediately turn right (north) to access the gate that leads to the parking garage.</li>
                    <li>If the gate arm is down, press the silver button for the intercom and tell the guard you are here for the event at HSC.</li>
                    <li>Drive to the 4th level and park close to the elevator bank</li>
                    <li>Take the elevator to the ground floor and enter the building from the west side</li>
                    <li>Walk to the lobby and take the elevators up to the 5th floor. Ask the security guard to direct you if needed.</li>
                    <li>Exit the elevator and enter the double glass doors. Tell the receptionist at the front desk that you are here for the FWIC event and she will direct you.</li>
                </ol>
            </div>

        </>
    );
};

export default BusinessPioneersClub ;
